import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BgImg from "../images/green.jpg"
import Slider from "../components/slick"
import Form from "../components/contact"
import Footer from "../components/footer"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="もっこうさんのかぐのはなし" />
      <section className="bg-gray-light">
        <h2 className="mb-8 py-4 text-3xl text-green-light text-center">
          もっこうさんのおはなし
        </h2>
        <div className="lg:flex lg:flex-row-reverse lg:relative">
          <div className="text-center py-8 max-w-xs m-auto lg:max-w-lg lg:text-left">
            <StaticImage
              src="../images/copy.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="大川は、木育と森林保全に取り組んでいます。"
              className="lg:"
            />
          </div>
          <div className="max-w-xs mx-auto lg:mt-64 lg:text-right">
            <StaticImage
              src="../images/mokkosan.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="もっこうさんイラスト"
              className="align-bottom lg:absolute lg:bottom-0 lg:left-32 "
            />
          </div>
        </div>
      </section>
      <section>
        <h2 className="my-8 py-4 text-3xl text-green-light text-center">
          森林と木工のサイクル
        </h2>
        <StaticImage
          src="../images/mountain.png"
          width={1368}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="山のイラスト"
          className="align-bottom"
        />
        <div
          style={{
            backgroundImage: `url(${BgImg})`,
            backgroundSize: `cover`,
            backgroundPotion: `top`,
            marginTop: `-2px`,
          }}
        >
          <p className="px-16 pt-6 pb-10 text-white leading-loose lg:max-w-2xl lg:m-auto lg:-mt-4">
            地球温暖化や大気汚染、土砂災害など私たちを取り巻く様々な環境問
            題の解決策として、今、森林が注目されています。
            <br />
            <br />
            地球温暖化の原因となっている二酸化炭素の吸収、洪水や山崩れを防止する高い保水力など、
            健全な森林は、多くの恵をもたらし、人々に安全安心で快適な暮らしに
            寄与しています。
            <br /> <br />
            木を扱うモノづくりのまち、日本有数の家具産地「大川」だからこそ、
            森林環境の整備・保全において、担う役割が大きいものと考えています。
          </p>
        </div>
        <div className="lg:flex lg:mt-8">
          <div className="text-center py-8 lg:w-1/2 lg:text-right lg:pr-8">
            <StaticImage
              src="../images/cycle-pc.png"
              width={400}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="森林整備サイクルのイラスト"
            />
          </div>
          <div className="text-center pt-8 lg:w-1/2 lg:text-left lg:pl-8 lg:mt-6">
            <StaticImage
              src="../images/p-cycle.png"
              width={300}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="森林整備サイクルの説明"
            />
          </div>
        </div>
      </section>
      <section>
        <h2 className="my-8 py-4 text-3xl text-green-light text-center">
          絵本を配っています
        </h2>
        <div className="lg:flex">
          <div className="text-center pt-8 lg:pt-0 lg:w-1/2 lg:text-right lg:pr-8 lg:mt-12">
            <StaticImage
              src="../images/book.png"
              width={300}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="もっこうさんの絵本"
              className="shadow-md"
            />
          </div>
          <p className="px-16 py-8 leading-loose lg:max-w-sm">
            かつて筑後川の川上である日田などで切り出した木材を筏に組み、川下の大川へと運搬していた歴史をモチーフに、木を使うことの大切さを知ってほしくて、絵本の物語をつくりました。
          </p>
        </div>
      </section>
      <section className="bg-gray-light">
        <h2 className="mb-8 pt-16 text-3xl text-green-light text-center">
          もっこうさんがつくるもの
        </h2>
        <div>
          <p className="px-16 py-8 leading-loose lg:max-w-2xl lg:m-auto">
            家具産地大川では、国産材、県産材の家具の開発、製造に取り組み、サスティナブルなものづくりに挑戦しています。
            その一部の「もっこうさん」達をご紹介します。
          </p>
        </div>
        <div className="w-full pb-8 mt-8">
          <Slider />
        </div>
      </section>
      <section>
        <Form />
      </section>
      <Footer />
    </Layout>
  )
}

export default IndexPage
