import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  return (
    <footer className="bg-green-dark">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 flex items-center justify-between lg:px-8">
        <div className="flex lg:px-8 justify-center space-x-6 order-2">
          <StaticImage
            src="../images/logo_var.png"
            width={97}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="大川市ロゴ"
          />
        </div>
        <div className="mt-8 lg:px-8 md:mt-0 md:order-1 relative">
          <div>
            <p className="text-base text-white">
              一般財団法人
              <br />
              大川インテリア振興センター
              <br />
            </p>
          </div>
          <div>
            <p className="text-sm text-white pt-4">
              福岡県大川市大字酒見256-1
              <br /> TEL:0944-87-0035 <br />
              10:00-17:00
              <br />
              URL:
              <a href="http://www.okawajapan.jp/" rel="noreferrer">
                http://www.okawajapan.jp/
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
