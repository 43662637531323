import React, { Component } from "react"
import Slider from "react-slick"
import { StaticImage } from "gatsby-plugin-image"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Ask1 from "../images/slider/asuka-01.jpg"
import Ask2 from "../images/slider/asuka-02.jpg"
import Ask3 from "../images/slider/asuka-03.jpg"
import Ask4 from "../images/slider/asuka-04.jpg"
import Ksi1 from "../images/slider/kisai-01.jpg"
import Ksi2 from "../images/slider/kisai-02.jpg"
import Ksi3 from "../images/slider/kisai-03.jpg"
import Ksi4 from "../images/slider/kisai-04.jpg"
import Ttn1 from "../images/slider/tateno-01.jpg"
import Ttn2 from "../images/slider/tateno-02.jpg"
import Ttn3 from "../images/slider/tateno-03.jpg"
import Ttn4 from "../images/slider/tateno-04.jpg"
import Nky1 from "../images/slider/nakayama-01.jpg"
import Nky2 from "../images/slider/nakayama-02.jpg"
import Nky3 from "../images/slider/nakayama-03.jpg"
import Mry1 from "../images/slider/maruyama-01.jpg"
import Mry2 from "../images/slider/maruyama-02.jpg"
import Mry3 from "../images/slider/maruyama-03.jpg"
import Mrs1 from "../images/slider/marusen-01.jpg"
import Mrs2 from "../images/slider/marusen-02.jpg"

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    }
    return (
      <div className="text-center max-w-xs m-auto lg:max-w-xl lg:grid lg:grid-cols-2 lg:gap-3">
        <div className="mb-16">
          <div className="relative">
            <div className="absolute z-10">
              <StaticImage
                src="../images/mokko-l.png"
                width={75}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="もっこうさんイラスト"
              />
            </div>
          </div>
          <h3 className="block mb-2 text-green-light">飛鳥工房</h3>
          <span className="block pb-2 text-green-light">木のおもちゃ</span>
          <Slider {...settings}>
            <img src={Ask1} alt="飛鳥工房製作木のおもちゃ" />
            <img src={Ask2} alt="飛鳥工房製作木のおもちゃ" />
            <img src={Ask3} alt="飛鳥工房製作木のおもちゃ" />
            <img src={Ask4} alt="飛鳥工房製作木のおもちゃ" />
          </Slider>
        </div>
        <div className="mb-16">
          <div className="relative lg:hidden">
            <div className="absolute right-0 z-10">
              <StaticImage
                src="../images/mokko-r.png"
                width={75}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="もっこうさんイラスト"
              />
            </div>
          </div>
          <h3 className="block mb-2 text-green-light">木彩工房</h3>
          <span className="block pb-2 text-green-light">
            保育所向け家具・遊具
          </span>
          <Slider {...settings}>
            <img src={Ksi1} alt="木彩工房製作木のおもちゃ" />
            <img src={Ksi2} alt="木彩工房製作木のおもちゃ" />
            <img src={Ksi3} alt="木彩工房製作木のおもちゃ" />
            <img src={Ksi4} alt="木彩工房製作木のおもちゃ" />
          </Slider>
        </div>
        <div className="mb-16">
          <div className="relative lg:hidden">
            <div className="absolute z-10">
              <StaticImage
                src="../images/mokko-l.png"
                width={75}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="もっこうさんイラスト"
              />
            </div>
          </div>
          <h3 className="block mb-2 text-green-light">立野木材工芸</h3>
          <span className="block pb-2 text-green-light">保育所の特注家具</span>
          <Slider {...settings}>
            <img src={Ttn1} alt="立野木材工芸製作保育所向け家具" />
            <img src={Ttn2} alt="立野木材工芸製作保育所向け家具" />
            <img src={Ttn3} alt="立野木材工芸製作保育所向け家具" />
            <img src={Ttn4} alt="立野木材工芸製作保育所向け家具" />
          </Slider>
        </div>
        <div className="mb-16">
          <div className="relative">
            <div className="absolute right-0 z-10">
              <StaticImage
                src="../images/mokko-r.png"
                width={75}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="もっこうさんイラスト"
              />
            </div>
          </div>
          <h3 className="block mb-2 text-green-light">ナカヤマ木工</h3>
          <span className="block pb-2 text-green-light">子ども用椅子</span>
          <Slider {...settings}>
            <img src={Nky1} alt="ナカヤマ木工製作子ども用椅子" />
            <img src={Nky2} alt="ナカヤマ木工製作子ども用椅子" />
            <img src={Nky3} alt="ナカヤマ木工製作子ども用椅子" />
          </Slider>
        </div>
        <div className="mb-16">
          <div className="relative">
            <div className="absolute z-10">
              <StaticImage
                src="../images/mokko-l.png"
                width={75}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="もっこうさんイラスト"
              />
            </div>
          </div>
          <h3 className="block mb-2 text-green-light">丸仙工業</h3>
          <span className="block pb-2 text-green-light">保育所向け遊具</span>
          <Slider {...settings}>
            <img src={Mrs1} alt="丸仙工業製作木の保育所向け遊具" />
            <img src={Mrs2} alt="丸仙工業製作木の保育所向け遊具" />
          </Slider>
        </div>
        <div className="mb-16">
          <div className="relative lg:hidden">
            <div className="absolute right-0 z-10">
              <StaticImage
                src="../images/mokko-r.png"
                width={75}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="もっこうさんイラスト"
              />
            </div>
          </div>
          <h3 className="block mb-2 text-green-light">マルヤマ木工</h3>
          <span className="block pb-2 text-green-light">幼児向け家具</span>
          <Slider {...settings}>
            <img src={Mry1} alt="マルヤマ木工製作幼児向け家具" />
            <img src={Mry2} alt="マルヤマ木工製作幼児向け家具" />
            <img src={Mry3} alt="マルヤマ木工製作幼児向け家具" />
          </Slider>
        </div>
      </div>
    )
  }
}
